const About = () => {
  return (
    <>
      <div id="about" className="text-center p-5">
        <h2 className="about-title">VHAGAR - THE IDENTITY</h2>
        <p className="text">
        Introducing VHAGAR—the next big thing in the meme coin universe, where dragons reign supreme! As the first dragon meme coin on the Solana Blockchain, VHAGAR is set to ignite a new era of crypto, leaving the old cat and dog meme tokens in the dust. But this isn't just a coin; it's a movement powered by a passionate community. Together, we’ll push VHAGAR to achieve a billion-dollar market cap and beyond. Join us in making history—because in the Draconian Era, we rise together!
        <br/><br/>
        Vhagar is a community-driven meme project on Solana, featuring a deflationary token model with a 2% buy/sell tax. 
        This tax will be used solely for the subsequent buyback and burning of the $Vhagar token, which will explicitly lead to a reduction in token circulation over time. 
        Vhagar will prioritize community engagement through regular events. Leveraging the Solana blockchain, the project will ensure transparency and accountability.
        </p>
        <div>
          <img src="../images/about_chart.png" alt="About Chart" />
        </div>
      </div>
    </>
  );
};

export default About;
